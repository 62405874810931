@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #ffffff;
  --foreground: #171717;
}

::selection {
  @apply bg-pink-600 text-white;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
}

body {
  @apply font-sans bg-gray-100 text-black;
}

.normal-table {
  th {
    @apply bg-gray-800 text-gray-100 font-semibold text-sm sticky top-0;
  }
  th:first-child {
    @apply rounded-tl-lg;
  }
  th:last-child {
    @apply rounded-tr-lg;
  }
  td {
    @apply px-2;
  }
  td:first-child {
    @apply border-l border-gray-300;
  }
  td:last-child {
    @apply border-r border-gray-300;
  }
  tr {
    @apply border-b border-gray-300;
  }
  /* Round the corners on the bottom */
  tr:last-child td:first-child {
    @apply rounded-bl-lg;
  }
  tr:last-child td:last-child {
    @apply rounded-br-lg;
  }
}

@keyframes bright-flash {
  0% {
    filter: brightness(1);
  }
  10% {
    filter: brightness(2); /* Increase brightness */
  }
  100% {
    filter: brightness(1); /* Return to normal */
  }
}

.flash-active {
  animation: bright-flash 0.2s ease-in-out;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.small-sublists.unreset ul li {
  font-size: 90%;
}

/* This keeps the top-level <p> in Markdown from adding a top/bottom margin */
.unreset > div > p:first-child,
.unreset > p:first-child {
  margin-top: 0;
}

unreset > div > p:last-child,
.unreset > p:last-child {
  margin-bottom: 0;
}

.unreset > div > h1,
.unreset > div > h2,
.unreset > div > h3,
.unreset > div > h4,
.unreset > div > h5,
.unreset > div > h6 {
  margin-top: 0;
}

.unreset > div > p:last-child {
  margin-bottom: 0;
}

.unreset pre {
  white-space: pre-wrap;
}

.unreset.clickable ol > li {
  cursor: default;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.unreset {
  /**
 * Unreset CSS
 *
 * Copyright (C) 2016 Kite (ixkaito@gmail.com)
 * License MIT
 * Version 1.0.0
 *
 * Unreset CSS restores browsers' default element styles which are reset by
 * Eric Meyer's Reset CSS, YUI 3 Reset CSS, HTML5 Reset Stylesheet of HTML5
 * Doctor, or Tim Murtaugh's HTML5 Reset.
 */

  html {
    box-sizing: content-box;
  }

  body {
    margin: 8px;
    line-height: normal;
  }

  p {
    margin: 1em 0;
  }

  blockquote,
  figure {
    margin: 1em 40px;
  }

  q {
    quotes: "“" "”" "‘" "’";

    &:before {
      content: open-quote;
    }

    &:after {
      content: close-quote;
    }
  }

  hr {
    border: 1px inset;
    box-sizing: border-box;
    margin: 0.5em auto;
  }

  h1 {
    font-size: 2em;
    font-weight: bold;
    margin: 0.67em 0;
  }

  h2 {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0.83em 0;
  }

  h3 {
    font-size: 1.17em;
    font-weight: bold;
    margin: 1em 0;
  }

  h4 {
    font-size: 1em;
    font-weight: bold;
    margin: 1.33em 0;
  }

  h5 {
    font-size: 0.83em;
    font-weight: bold;
    margin: 1.67em 0;
  }

  h6 {
    font-size: 0.67em;
    font-weight: bold;
    margin: 2.33em 0;
  }

  article,
  aside,
  nav,
  section {
    h1 {
      font-size: 1.5em;
      font-weight: bold;
      margin: 0.83em 0;
    }

    article,
    aside,
    nav,
    section {
      h1 {
        font-size: 1.17em;
        font-weight: bold;
        margin: 1em 0;
      }

      article,
      aside,
      nav,
      section {
        h1 {
          font-size: 1em;
          font-weight: bold;
          margin: 1.33em 0;
        }

        article,
        aside,
        nav,
        section {
          h1 {
            font-size: 0.83em;
            font-weight: bold;
            margin: 1.67em 0;
          }

          article,
          aside,
          nav,
          section {
            h1 {
              font-size: 0.67em;
              font-weight: bold;
              margin: 2.33em 0;
            }
          }
        }
      }
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 2px;
    border-color: gray;
  }

  thead,
  tbody,
  tfoot,
  tr {
    border-color: inherit;
    vertical-align: middle;
  }

  td,
  th {
    padding: 1px;
    vertical-align: inherit;
  }

  th {
    font-weight: bold;
  }

  caption {
    text-align: center;
  }

  ul,
  menu {
    list-style-type: disc;
    margin: 0.5em 0;
    padding: 0 0 0 20px;
  }

  ol {
    list-style-type: decimal;
    margin: 0.5em 0;
    padding: 0 0 0 20px;
  }

  li > ol,
  li > ul {
    margin: 0 0 0.5em 0;
  }

  /* Markdown creates this... */
  li > p:first-child {
    margin-top: 0;
  }

  li > p:last-child {
    margin-bottom: 0;
  }

  li > p + ol,
  li > p + ul {
    margin-top: -1em;
  }

  ul,
  ol {
    ul {
      list-style-type: circle;
    }

    ul,
    ol {
      ul {
        list-style-type: square;
      }
    }
  }

  dd {
    margin: 0 0 0 40px;
  }

  dl {
    margin: 1em 0;
  }

  ul,
  ol,
  menu,
  dl {
    ul,
    ol,
    menu,
    dl {
      margin: 0;
    }
  }

  legend {
    padding: 0 2px;
  }

  fieldset {
    border-style: groove;
    border-width: 2px;
    border: 2px groove ThreeDFace;
    margin: 0 2px;
    padding: 0 2px 3px;
    -webkit-padding-before: 0.35em;
    -webkit-padding-start: 0.75em;
    -webkit-padding-end: 0.75em;
    -webkit-padding-after: 0.625em;
  }

  ins {
    background-color: transparent;
    font-weight: inherit;
    text-decoration: underline;
  }

  b,
  strong {
    font-weight: bold;
  }

  i,
  cite,
  em,
  var,
  address,
  dfn {
    font-style: italic;
    font-weight: inherit;
  }

  abbr[title],
  dfn[title] {
    border-bottom: 0;
    cursor: default;
    font-weight: inherit;
  }

  tt,
  code,
  kbd,
  samp {
    font-family: monospace;
    font-weight: inherit;
  }

  pre {
    font-family: monospace;
    margin: 1em 0;
    /* white-space: pre; */
  }

  mark {
    background-color: yellow;
    color: black;
    font-style: normal;
    font-weight: inherit;
  }

  big {
    font-size: larger;
    font-weight: inherit;
  }

  small {
    font-size: smaller;
    font-weight: inherit;
  }

  sub,
  sup {
    font-weight: inherit;
    line-height: inherit;
    position: static;
  }

  sub {
    font-size: smaller;
    bottom: 0;
    vertical-align: sub;
  }

  sup {
    font-size: smaller;
    top: 0;
    vertical-align: super;
  }

  ruby {
    > rt {
      font-size: 50%;
    }
  }

  iframe {
    border: 2px inset;
  }

  a {
    color: #3b82f6;
    text-decoration: underline;
  }

  a:hover {
    color: #1d4ed8;
  }
}

.unreset.light-link {
  a {
    @apply text-sky-200;
  }

  a:hover {
    @apply text-sky-100;
  }
}
